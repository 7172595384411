<template>
  <div
    class="Dashboard"
    :class="{ 'Dashboard--empty': !hasCards }"
  >
    <AppBarLayout
      class="Dashboard__app-bar"
      action-buttons-class="ml-16"
    >
      <template #header>
        {{ projectId ? $t('dashboard.Dashboard') : $t('dashboard.CrossProjectDashboard') }}
      </template>

      <template #actions>
        <div class="Dashboard__app-bar-actions d-flex flex-nowrap">
          <v-btn
            key="create"
            color="primary"
            depressed
            :to="createCardRoute"
            :disabled="loading || (projectId != null && issueSchemaIsEmpty)"
            replace
          >
            <v-icon
              class="mr-2"
              v-text="'mdi-plus'"
            />
            {{ $t('dashboard.Card') }}
          </v-btn>
          <v-btn
            key="manage"
            color="primary"
            class="ml-2"
            outlined
            :disabled="loading || !hasCards"
            :to="dashboardManageRoute"
          >
            {{ $t('dashboard.ManageCards') }}
          </v-btn>
        </div>
      </template>
    </AppBarLayout>

    <EmptyState
      v-if="!loading && !hasCards"
      :title="(projectId && issueSchemaIsEmpty)
        ? $t('dashboard.CreateCardsUnavailableM')
        : $t('dashboard.CreateCardsM')"
    >
      <template
        v-if="!projectId || issueSchemaIsNotEmpty"
        #actions
      >
        <v-btn
          color="primary"
          depressed
          :to="createCardRoute"
        >
          <v-icon v-text="'mdi-plus'" />
          {{ $t('dashboard.Card') }}
        </v-btn>
      </template>
    </EmptyState>

    <!-- grid with the cards -->
    <section
      v-show="hasCards"
      class="Dashboard__rows"
    >
      <div
        v-for="row in orderedRows"
        :key="row.key"
        v-mutate.char.child.sub="() => onRowMutated(row.key, $refs['cards' + row.key][0])"
        class="Dashboard__row"
        :class="'Dashboard__row' + row.key"
      >
        <div
          :ref="'cards' + row.key"
          class="Dashboard__cards"
        >
          <!-- list of cards in the row -->
          <DashboardCard
            v-for="card in row.cards"
            :key="card.id"
            :project-id="projectId"
            :card="card"
            :disabled="loading"
            :highlighted="card.id === highlightCardId"
            class="Dashboard__card"
          />
        </div>

        <template v-if="scrollableRows[row.key]">
          <v-btn
            absolute
            left
            icon
            small
            class="Dashboard__scroll-btn Dashboard__scroll-btn--left"
            @mousedown="scrollSpeed[row.key] = -1 * SCROLL_SPEED.INITIAL"
            @mouseup="scrollSpeed[row.key] = 0"
            @mouseleave="scrollSpeed[row.key] = 0"
            @blur="scrollSpeed[row.key] = 0"
          >
            <v-icon v-text="'mdi-chevron-left'" />
          </v-btn>
          <v-btn
            absolute
            right
            icon
            small
            class="Dashboard__scroll-btn Dashboard__scroll-btn--right"
            @mousedown="scrollSpeed[row.key] = SCROLL_SPEED.INITIAL"
            @mouseup="scrollSpeed[row.key] = 0"
            @mouseleave="scrollSpeed[row.key] = 0"
            @blur="scrollSpeed[row.key] = 0"
          >
            <v-icon v-text="'mdi-chevron-right'" />
          </v-btn>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import * as R from 'ramda'

import {
  CARD_TYPE,
  DASHBOARD,
  DASHBOARD_CARDS_POLL_INTERVAL,
} from '../constants'
import { reportError } from '../helpers'

import Dashboard from '../store/orm/dashboard'
import DashboardCard from '../store/orm/dashboardCard'
import Issue from '../store/orm/issue'
import Project from '../store/orm/project'

import DashboardCardComponent from '../components/DashboardCard'
import EmptyState from '../components/EmptyState'
import AppBarLayout from '../layouts/AppBarLayout'

const SCROLL_SPEED = Object.freeze({
  // Considering animation frame to be 16-17ms
  INITIAL: 5, // px per animation frame
  MAX: 40, // px per animation frame
  ACCEL: 0.1, // px per animation frame squared
})

export default {
  name: 'Dashboard',

  components: {
    AppBarLayout,
    DashboardCard: DashboardCardComponent,
    EmptyState,
  },

  metaInfo() {
    const { $store, projectId, project, dashboard } = this
    return {
      title: projectId
        ? $store.getters.title(project ? project.name : this.$t('dashboard.LoadingProject'))
        : $store.getters.title(dashboard ? this.$t('dashboard.Dashboard') : this.$t('dashboard.Loading')),
    }
  },

  props: {
    projectId: { type: String, default: null },
    highlightCardId: { type: Number, default: null },
  },

  data: () => ({
    AB_COUNTER_ROW: DASHBOARD.AB_COUNTER_ROW,
    PIE_ROW: DASHBOARD.PIE_ROW,
    TABLE_ROW: DASHBOARD.TABLE_ROW,
    SIMPLE_COUNTER_COLUMN: DASHBOARD.SIMPLE_COUNTER_COLUMN,
    CHECKLIST_STATUS: DASHBOARD.CHECKLIST_STATUS,

    SCROLL_SPEED,

    scrollableRows: {
      [DASHBOARD.SIMPLE_COUNTER_ROW]: false,
      [DASHBOARD.AB_COUNTER_ROW]: false,
      [DASHBOARD.PIE_ROW]: false,
      [DASHBOARD.TABLE_ROW]: false,
    },

    scrollSpeed: {
      [DASHBOARD.SIMPLE_COUNTER_ROW]: 0,
      [DASHBOARD.AB_COUNTER_ROW]: 0,
      [DASHBOARD.PIE_ROW]: 0,
      [DASHBOARD.TABLE_ROW]: 0,
    },

    loading: false,
    pollIntervalId: null,
  }),

  computed: {
    dashboardId() { return this.projectId || Dashboard.GLOBAL_ID },

    project() {
      const { projectId } = this
      return projectId && Project.find(projectId)
    },

    // preset (customized fields) for project issues
    issueSchema() { return this.$store.getters['issueSchema/get'](this.projectId) },
    issueSchemaIsEmpty() {
      const { projectId, issueSchema } = this
      return projectId ? issueSchema === null : null
    },
    issueSchemaIsNotEmpty() {
      const { projectId, issueSchema } = this
      return projectId ? issueSchema != null : null
    },

    // dashboard is a set of cards and some meta
    dashboard() {
      const { dashboardId } = this
      return Dashboard.query().with('cards').find(dashboardId)
    },

    hasCards() {
      const { dashboard } = this
      return dashboard != null && dashboard.cards.length > 0
    },

    // cards grouped by row:  (A/B) counters, pie charts, tables, ...
    rows() {
      const { dashboard } = this
      return dashboard && dashboard.orderedCards && {
        [DASHBOARD.SIMPLE_COUNTER_ROW]: dashboard.orderedCards
          .filter(card => card.cardType === CARD_TYPE.counter),

        [DASHBOARD.AB_COUNTER_ROW]: dashboard.orderedCards
          .filter(card => card.cardType === CARD_TYPE.aOfB),

        [DASHBOARD.CHECKLIST_STATUS]: dashboard.orderedCards
          .filter(card => card.cardType === CARD_TYPE.checklistStatus),

        [DASHBOARD.PIE_ROW]: dashboard.orderedCards
          .filter(card => card.cardType === CARD_TYPE.pieChart),

        [DASHBOARD.TABLE_ROW]: dashboard.orderedCards
          .filter(card => card.cardType === CARD_TYPE.table),

        [DASHBOARD.CHECKLIST_STATUS]: dashboard.orderedCards
          .filter(card => card.cardType === CARD_TYPE.checklistStatus),
      }
    },

    // return the row order of the current dashboard,
    // uses fallback if the value isn't loaded or is invalid
    rowOrder() {
      const { projectId, dashboard } = this
      const rowOrder = dashboard?.metaData?.rowOrder
      const fallback = [
        ...(projectId
          ? DASHBOARD.PROJECT_DEFAULT_ROWS
          : DASHBOARD.CROSS_PROJECT_DEFAULT_ROWS),
      ]
      // validates that rowOrder makes sense in terms of values
      return R.equals(
        R.sortBy(R.identity, rowOrder || []),
        R.sortBy(R.identity, fallback),
      )
        ? rowOrder
        : fallback
    },

    // same rows but sorted according to the user choice
    orderedRows() {
      const { rowOrder, rows } = this

      return rowOrder
        .map(key => ({
          key,
          cards: rows?.[key] || null,
        }))
        .filter(row => row.cards?.length)
    },

    createCardRoute() {
      const { projectId } = this
      return projectId
        ? {
          name: 'ProjectEditCard',
          params: { projectId },
        }
        : { name: 'EditCard' }
    },

    dashboardManageRoute() {
      const { projectId } = this
      return projectId
        ? {
          name: 'ProjectDashboardManage',
          params: { projectId },
        }
        : { name: 'DashboardManage' }
    },
  },

  watch: {
    // start loading data on new `projectId` change
    projectId: {
      handler(projectId) {
        this.loadData()
        if (projectId) this.startPolling()
        else this.stopPolling()
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.stopPolling()
  },

  mounted() {
    [
      DASHBOARD.SIMPLE_COUNTER_ROW,
      DASHBOARD.AB_COUNTER_ROW,
      DASHBOARD.PIE_ROW,
      DASHBOARD.TABLE_ROW,
      DASHBOARD.CHECKLIST_STATUS,
    ]
      .forEach(rowKey =>
        this.onRowMutated(rowKey, this.$refs['cards' + rowKey]?.[0]))

    this.watchScrolling()
  },

  methods: {
    startPolling() {
      const { projectId } = this
      this.pollIntervalId = setInterval(() => {
        if (!this.projectId) {
          this.stopPolling()
          throw new Error(this.$t('dashboard.ErrorCannotPollDashboardM'))
        }
        if (this.pollIntervalId != null) {
          Dashboard.dispatch('$getForProjectWithValues', { projectId, types: 'checklist_status' })
        }
      }, DASHBOARD_CARDS_POLL_INTERVAL)
    },

    stopPolling() {
      clearInterval(this.pollIntervalId)
      this.pollIntervalId = null
    },

    async loadData() {
      this.loading = true

      try {
        await this.loadProjects()
        const promises = [
          this.loadDashboard(),
          this.loadIssueStatuses(),
        ]
        if (this.project) {
          promises.push(
            this.loadIssueSchema(),
            this.loadChecklists(),
          )
        }
        await Promise.all(promises)
      } catch (e) {
        reportError(e).catch(e => console.error(e))
      } finally {
        this.loading = false
      }
    },

    loadProjects() { return Project.dispatch('$getList') },

    loadIssueSchema() {
      const { $store, projectId } = this
      if (!projectId) throw new Error('Cannot fetch issue schema without projectId')
      return $store.dispatch('issueSchema/get', { projectId, reload: false })
    },

    loadIssueStatuses() {
      const { $store, projectId } = this
      // PTS-136: it is okay now to load shared issue statuses
      // if (!projectId) {
      //   throw new Error('Cannot fetch issue status without projectId')
      // }
      return $store.dispatch('$issueStatus/getList', { projectId, reload: false })
    },

    loadDashboard() {
      const { dashboardId } = this

      return Dashboard.dispatch('$get', { id: dashboardId })
        // no promise returned: value loading should be non-blocking
        .then(() => {
          this.loadCardValues()
          this.maybeLoadTotalIssues()
        })
    },
    loadCardValues() {
      const { dashboard, dashboardId } = this
      return dashboard
        ? Promise.all(
          dashboard.cards.map(({ id: cardId }) =>
            DashboardCard.dispatch('$getDetails', { dashboardId, cardId })),
        )
        : Promise.reject(new Error("project or dashboard isn't loaded"))
    },
    async maybeLoadTotalIssues() {
      const { dashboard, projectId } = this
      if (!dashboard) throw new Error("project or dashboard isn't loaded")

      const hasCounters = dashboard.cards
        .some(card => card.cardType === CARD_TYPE.counter)
      if (hasCounters) await Issue.dispatch('$getTotal', { projectId })
    },

    loadChecklists() {
      const { $store, projectId } = this
      if (!projectId) throw new Error('Cannot load checklists without projectId')
      return $store.dispatch('checklist/getChecklist', { projectId })
    },

    onRowMutated(rowKey, row) {
      // console.log(rowKey, row, 'was mutated')
      this.scrollableRows[rowKey] =
        row != null && row.scrollWidth > row.clientWidth
    },

    watchScrolling() {
      [
        DASHBOARD.SIMPLE_COUNTER_ROW,
        DASHBOARD.AB_COUNTER_ROW,
        DASHBOARD.PIE_ROW,
        DASHBOARD.TABLE_ROW,
        DASHBOARD.CHECKLIST_STATUS,
      ]
        .forEach(rowKey => {
          const unwatch = this.$watch(
            () => this.scrollSpeed[rowKey],
            function(newSpeed, prevSpeed) {
              if (prevSpeed === 0 && newSpeed !== 0) {
                this.animateScroll(rowKey)
              }
            },
          )
          this.$once('hook:beforeDestroy', unwatch)
        })
    },

    animateScroll(rowKey) {
      // console.log(`animateScroll(rowKey = ${rowKey})`)

      const speed = this.scrollSpeed[rowKey]
      if (speed === 0) return
      const container = this.$refs['cards' + rowKey][0]
      container.scrollLeft += speed
      // console.log('scrolled', container, 'by', speed)

      this.scrollSpeed[rowKey] += Math.sign(speed) * SCROLL_SPEED.ACCEL
      requestAnimationFrame(() => this.animateScroll(rowKey))
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/mixins'
@import '../scss/variables'

.Dashboard
  position: relative
  background: #EDF0FA // blue-grey-225/5-98

  &--empty
    display: flex
    height: 100%

  &__header
    position: sticky
    top: 56px
    z-index: 4

    margin-left: -50px
    margin-right: -50px
    padding-left: 50px
    padding-right: 50px
    width: calc(100% + 100px)

    background: #fafafa
    font-weight: 500
    font-size: 36px

    display: flex
    align-items: center
    min-height: 90px

    @media #{map-get($display-breakpoints, 'md-and-up')}
      top: 64px

  &__meta
    display: flex
    justify-content: flex-start
    align-items: center
    margin-bottom: 32px
    font-size: 14px
    line-height: 20px

    .meta__item
      display: flex
      align-items: center
      flex: 0

      @media #{map-get($display-breakpoints, 'sm-and-down')}
        flex: 0 0 200px

    .meta__counter
      font-size: 24px
      line-height: 100%
      font-weight: normal
      margin-right: 8px

    .meta__sup
      font-weight: 600
      font-size: 18px
      line-height: 24px
      vertical-align: top

    .meta__label
      white-space: nowrap

  &__rows
    flex: 1
    padding: 40px 24px 24px
    display: flex
    flex-direction: column
    height: calc(100vh - 56px)
    overflow: hidden auto

    @media #{map-get($display-breakpoints, 'md-and-up')}
      height: calc(100vh - 64px)

  &__row
    flex: 1
    position: relative
    overflow: visible

    &--simple-counters
      .Dashboard__cards
        display: grid
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
        //grid-template-columns: repeat(auto-fill, 250px)
        margin: 0
        padding: 0
        grid-gap: 24px

    &--a-of-b-counters
      .Dashboard__cards
        display: grid
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
        //grid-template-columns: repeat(auto-fill, 250px)
        margin: 0
        padding: 0
        grid-gap: 24px

    &--pies
      // justify-content: space-around // task#1615
      // space-around doesn't work well with scroll
      background: white
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
      border-radius: 6px

      .Dashboard__cards
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
        grid-gap: 0

      .Dashboard__card
        margin: 0 auto !important
        padding-left: 30px
        padding-right: 30px
        min-width: 300px

    &--tables
      .Dashboard__cards
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))

      .Dashboard__card
        overflow: auto

    .Dashboard__row + &
      margin-top: 24px

    & + .Dashboard__row
      margin-top: 24px

  &__cards
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
    grid-gap: 24px

    @include tiny-scrollbar(rgba(125, 125, 179, 0.2), transparent, false, true)

  &__card
    min-height: 100px
    min-width: 208px

    &.DashboardCard--flat
      margin-top: 0
      margin-bottom: 0

  &__scroll-btn
    top: 50%
    transform: translateY(-50%)
    z-index: 3
    background: white
    box-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 4px rgba(0, 0, 0, .05)
    opacity: 0
    pointer-events: none

    &--left
      left: 16px

    &--right
      right: 0

  &__row:hover &__scroll-btn
    opacity: 1
    pointer-events: all

  &__row--pies &__scroll-btn--left
    left: 8px
  &__row--pies &__scroll-btn--right
    right: 8px
</style>
